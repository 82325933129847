import React, { useCallback, useMemo } from 'react';
import { useCourse } from '@brainstud/academy-api/Hooks/useCourses';
import { useEnrollment } from '@brainstud/academy-api/Hooks/useEnrollments';
import { useLearningObjectCollection } from '@brainstud/academy-api/Hooks/useLearningObjectCollections';
import { usePortalCoursesData } from '@brainstud/academy-api/Hooks/usePortalCourses';
import { useStudents } from '@brainstud/academy-api/Hooks/useStudents';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Dropdown } from '@brainstud/ui/Form Input/Dropdown';
import { ConfirmationModal } from '@brainstud/ui/Modals/ConfirmationModal';
import { Status } from '@brainstud/ui/Static/Status';
import { Edit, MoreVert } from '@mui/icons-material';
import classNames from 'classnames';
import { Container } from 'Components/Container';
import { Link } from 'Components/Link';
import { Tabs, TabsItem } from 'Components/Tabs';
import { useRouter } from 'next/router';
import { useBreadcrumbs } from 'Providers/BreadcrumbsProvider/useBreadcrumbs';
import { useLearningRouteProvider } from 'Providers/LearningRouteProvider/useLearningRouteProvider';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useToaster } from 'Providers/ToasterProvider/useToaster';
import { useTranslator } from 'Providers/Translator';
import { RouteLayoutModal } from 'Views/Courses/CollectionEditView/RouteLayoutModal';
import styles from './CourseNavigation.module.css';

const cx = classNames.bind(styles);

const CourseNavigation = () => {
  const [t] = useTranslator();
  const router = useRouter();

  const {
    courseId,
    collectionId: routeCollectionId,
    learningRouteId,
  } = router.query as {
    courseId: string;
    collectionId: string;
    learningRouteId?: string;
  };
  const [{ data: course }, { isLoading: isLoadingCourse }] = useCourse({
    course: courseId,
  });
  const [{ data: courses }, { isLoading: isLoadingCourses }] =
    usePortalCoursesData();
  const portalCourse = courses.find((item) => item.id === courseId);
  const enrollmentId = portalCourse?.enrollment?.()?.id;
  const { layout, learningRoute } = useLearningRouteProvider();

  const [
    { data: enrollment, update: updateEnrollment },
    { isLoading: isLoadingEnrollment },
  ] = useEnrollment(
    {
      enrollment: enrollmentId,
    },
    { enabled: !!enrollmentId }
  );
  const isLoadingAny =
    isLoadingCourse || isLoadingCourses || isLoadingEnrollment;

  const handleEnrollmentChange = useCallback(
    (value?: string) => {
      if (value !== undefined) {
        updateEnrollment.mutate(
          {
            _method: 'PATCH',
            relationships: {
              learning_object_collection: value,
            },
          },
          {
            onSuccess: () => {
              router.push(
                `/coach/courses/${courseId}/collections/${value}/students`
              );
            },
          }
        );
      }
    },
    [courseId, router, updateEnrollment]
  );

  const collections = useMemo(
    () =>
      course
        ?.learningObjectCollections?.()
        .sort((one, two) => (one.createdAt < two.createdAt ? 1 : -1)) || [],
    [course]
  );

  const enrollmentCollectionId = enrollment?.learningObjectCollection?.().id;
  const collectionId = useMemo(
    () => routeCollectionId || enrollmentCollectionId || collections[0]?.id,
    [routeCollectionId, collections, enrollmentCollectionId]
  );

  const [, { data: document }] = useStudents(
    {
      filter: {
        ...(routeCollectionId
          ? { learning_object_collection: collectionId }
          : { course: courseId }),
        archived: false,
      },
    },
    { enabled: !!routeCollectionId || !!courseId }
  );
  const totalCount = document?.meta?.total;

  const SBU = useMemo(
    () => course?.metadata?.informationLines?.sbu || false,
    [course]
  );

  const [
    { data: learningObjectCollection, update: updateLearningObjectCollection },
  ] = useLearningObjectCollection({
    learningObjectCollection: collectionId,
  });
  const editUrl =
    learningObjectCollection?.links?.edit?.href &&
    learningObjectCollection?.type === 'custom';

  const { openModal, closeModal } = useModals();
  const [setToast] = useToaster();
  const handleStatusUpdate = useCallback(
    (value?: string) => {
      if (value && value !== 'CONCEPT') {
        openModal(ConfirmationModal, {
          question: t(`navigation.courses.status.${value}.are_you_sure_live`),
          yes: t(`navigation.courses.status.${value}.confirm`),
          no: t(`navigation.courses.status.${value}.cancel`),
          handleConfirm: () =>
            updateLearningObjectCollection.mutateAsync(
              {
                _method: 'PATCH',
                status: value as 'CONCEPT' | 'PUBLISHED',
                allow_new_enrollments: value === 'PUBLISHED',
              },
              {
                onSettled: () => {
                  closeModal();
                },
                onSuccess: () => {
                  setToast(
                    t(`navigation.courses.status.${value}.success`),
                    'success'
                  );
                },
                onError: () => {
                  setToast(
                    t(`navigation.courses.status.${value}.error`),
                    'error'
                  );
                },
              }
            ),
        });
      }
    },
    [updateLearningObjectCollection, openModal, closeModal, t, setToast]
  );

  const isExternal = ['scorm', 'lti'].includes(course?.opener || '');

  useBreadcrumbs(
    {
      [courseId]: { text: portalCourse?.title, href: null },
      [collectionId]: {
        text: learningObjectCollection?.title,
        href: 'students',
      },
      ...(learningRouteId ? { [learningRouteId]: null } : {}),
      routes: null,
      collections: null,
    },
    [portalCourse, learningObjectCollection, learningRouteId]
  );

  return (
    <header className={cx(styles.root)}>
      <Container>
        <div className={cx(styles.flexHeader)}>
          <h2 className={cx(styles.title)}>{portalCourse?.title}</h2>
          <div style={{ flexGrow: 1 }} />
          {SBU && (
            <div className={cx(styles.sbu)}>
              <p className={cx(styles.metaTitle)}>
                {t('navigation.courses.sbu')}
              </p>
              <p>{SBU}</p>
            </div>
          )}
          {enrollment && !isExternal && (
            <Button
              quiet
              to={`/coach/courses/${courseId}/collections/${collectionId}/coaches`}
            >
              <span>{t('more')}</span>
              <MoreVert fontSize="large" />
            </Button>
          )}
        </div>
        {!isLoadingAny && !isExternal && (
          <div className={cx(styles.collectionNav)}>
            {course && (
              <Dropdown
                onChange={handleEnrollmentChange}
                value={collectionId}
                loading={!collectionId}
              >
                {course.learningObjectCollections?.().map((item) => (
                  <Dropdown.Option key={item.id} value={item.id}>
                    {item.title}
                  </Dropdown.Option>
                ))}
              </Dropdown>
            )}
            {editUrl && (
              <>
                <Dropdown
                  className={cx(styles.statusDropdown)}
                  onChange={(value) => handleStatusUpdate(value)}
                  value={learningObjectCollection?.status}
                >
                  <Dropdown.Option
                    value="CONCEPT"
                    disabled={learningObjectCollection?.status !== 'CONCEPT'}
                  >
                    <Status scheme="blue" inline>
                      {t('views.courses.status.concept')}
                    </Status>
                  </Dropdown.Option>
                  <Dropdown.Option value="PUBLISHED">
                    <Status inline scheme="green">
                      {t('views.courses.status.published')}
                    </Status>
                  </Dropdown.Option>
                  <Dropdown.Option value="ARCHIVED">
                    <Status scheme="grey" inline>
                      {t('views.courses.status.archived')}
                    </Status>
                  </Dropdown.Option>
                </Dropdown>
                <div className={cx(styles.layout)}>
                  <Status scheme="info">
                    {t(`layouts.admin.collection_edit.${layout || 'fallback'}`)}
                  </Status>
                  <Button
                    type="button"
                    quiet
                    link
                    onClick={() =>
                      openModal(RouteLayoutModal, { layout, learningRoute })
                    }
                  >
                    <Edit />
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
        <Tabs>
          {/* Students here */}
          {enrollment && (
            <TabsItem
              component={Link}
              href={`/coach/courses/${courseId}/${isExternal ? 'students' : `collections/${collectionId}/students`}`}
              label={`${t('navigation.students')} (${totalCount || '0'})`}
            />
          )}
          <TabsItem
            component={Link}
            href={`/coach/courses/${courseId}/${isExternal ? 'content' : `collections/${collectionId}/content`}`}
            label={t('navigation.assignments')}
          />
          {!isExternal && (
            <>
              <TabsItem
                component={Link}
                href={`/coach/courses/${courseId}/collections/${collectionId}/toolkits`}
                label={t('navigation.toolkit')}
              />
              <TabsItem
                component={Link}
                href={`/coach/courses/${courseId}/collections/${collectionId}/exams`}
                label={t('navigation.exams')}
              />
              <TabsItem
                component={Link}
                href={`/coach/courses/${courseId}/collections/${collectionId}/qualifications`}
                label={t('navigation.qualifications')}
              />
            </>
          )}
        </Tabs>
      </Container>
    </header>
  );
};

export default CourseNavigation;
