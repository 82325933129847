import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  Course,
  CourseCreateInput,
  CourseFilters,
  CourseIncludes,
  CourseSorts,
  CourseUpdateInput,
} from '../Types/Resources/Course';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface ICourseProps
  extends HookParams<typeof CourseIncludes, CourseFilters, typeof CourseSorts> {
  course?: UUID;
  portal?: UUID;
}

export function usePortalCourse(
  { course, portal, ...queryParameters }: ICourseProps = {},
  settings?: IUseQueryOptions<DataDocument<Course>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { course: defaultCourse, portal: defaultPortal },
  } = context;

  const request = new ApiRequest(
    {
      enabled: !!(course || defaultCourse) && !!(portal || defaultPortal),
      baseName: 'v1.portals.courses',
      baseUri: `/v1/portals/${portal || defaultPortal}/courses`,
      invalidate: ['course', 'courses'],
      uri: `/${course}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Course, CourseCreateInput, CourseUpdateInput>(
    request,
    settings
  );
}

export function usePortalCourses(
  { portal, ...queryParameters }: Omit<ICourseProps, 'course'> = {},
  settings?: IUseQueryOptions<DataDocument<Course[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { portal: defaultPortal },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(portal || defaultPortal),
      baseName: 'v1.portals.courses',
      invalidate: ['course', 'courses'],
      baseUri: `/v1/portals/${portal || defaultPortal}/courses`,
      queryParameters,
    },
    context
  );
  return useIndexCR<Course, CourseCreateInput>(request, settings);
}

/**
 * Wrapper around usePortalCourses with predefined set of includes.
 */
export function usePortalCoursesData() {
  return usePortalCourses({
    include: [
      'lti_resource_links',
      'learning_object_collections',
      'certifications',
    ],
  });
}
