import React, { useCallback, useState } from 'react';
import { useLearningRoute } from '@brainstud/academy-api/Hooks/useLearningRoutes';
import { LearningRoute } from '@brainstud/academy-api/Types/Resources/LearningRoute';
import { Button } from '@brainstud/ui/Buttons/Button';
import { SidebarModal } from '@brainstud/ui/Modals/SidebarModal';
import classNames from 'classnames/bind';
import { FlexContainer } from 'Components/FlexContainer';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useToaster } from 'Providers/ToasterProvider/useToaster';
import { useTranslator } from 'Providers/Translator';
import { LayoutSelector } from '../LayoutSelector/LayoutSelector';
import styles from './RouteLayoutModal.module.css';

const cx = classNames.bind(styles);

type Props = {
  className?: string;
  layout?: 'action-cards' | 'chapter-cards';
  learningRoute?: LearningRoute;
};

/**
 * RouteLayoutModal.
 *
 * SidebarModal which can be used to set a different route layout
 */
export const RouteLayoutModal = ({
  className,
  layout,
  learningRoute,
}: Props) => {
  const { closeModal } = useModals();
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const [tempLayout, setTempLayout] = useState<string>(
    layout || 'action-cards'
  );

  const [{ createOrUpdate }] = useLearningRoute({
    learningRoute: learningRoute?.id,
  });

  const handleSelectLayout = useCallback(() => {
    createOrUpdate
      .mutateAsync({
        ...(learningRoute ? { _method: 'patch' } : {}),
        layout: tempLayout,
      })
      .then(() => {
        setToast(
          t('views.courses.collection_edit.route_layout_modal.success'),
          'success'
        );
        closeModal();
      })
      .catch(() => {
        setToast(
          t('views.courses.collection_edit.route_layout_modal.error'),
          'error'
        );
      });
  }, [createOrUpdate, learningRoute, tempLayout, setToast, t, closeModal]);

  return (
    <SidebarModal
      onClickOutside={!!layout}
      onClose={layout ? closeModal : undefined}
      className={cx(styles.base, className)}
      size="medium"
    >
      <SidebarModal.Header className={cx(styles.header)}>
        <FlexContainer row justifyContent="space-between" alignItems="center">
          <h2>{t('views.courses.collection_edit.route_layout_modal.title')}</h2>
          <Button
            loading={createOrUpdate.isPending}
            onClick={handleSelectLayout}
          >
            {t('save')}
          </Button>
        </FlexContainer>
      </SidebarModal.Header>
      <div>
        <span>
          {t('views.courses.collection_edit.route_layout_modal.description')}
        </span>
        <div className={cx(styles.layouts)}>
          <LayoutSelector
            layoutType="route"
            setSelectedLayout={setTempLayout}
            selectedLayout={tempLayout}
            routeLayout={layout}
          />
        </div>
      </div>
    </SidebarModal>
  );
};
